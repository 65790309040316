
.shopDecoration-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep(.el-image) {
  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
    font-size: 14px;
  }
}
.grey {
  color: #999;
}
.red {
  color: #ff0000;
}
.shopDecoration {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.el-image {
  background: #f9f9f9;
}
