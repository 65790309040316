
/deep/ .el-scrollbar__bar .is-horizontal {
    display: none;
}

.liveMarket {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .liveMarket-header {
        width: 100%;
        padding-bottom: 10px;
        margin-left: 30px;
        margin-top: 30px;
    }

    .content_box {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 0 30px 0 30px;
        box-sizing: border-box;

        .content_box_text {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            color: #333333;
        }
    }
}

/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}

/deep/ .el-table.table-custom {
    color: #333333;

    th {
        color: #909399;
        background-color: #fafafa !important;
    }

    .el-table__row {
        height: 60px;
    }

    .el-link + .el-link {
        margin-left: 20px;
    }
}

.content {
    width: 100%;
    height: 660px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 20px;

    .left {
        width: 580px;
        min-width: 300px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        margin: 0px 40px 0px 2px;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);

        .left_top {
            max-width: 580px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .left-top-border-box {
                height: 100%;
                width: 2px;
                background: #F3F3F3;
                margin-left: 5px;
                margin-right: 15px;
                position: absolute;
                left: 60px;
                z-index: 1
            }

            .left_top_left {
                width: 60px;
            }

            .left_top_center {
                width: 12px;
                height: 12px;
                background: #D9D9D9;
                border-radius: 6px;
                margin-right: 10px;
            }

            .left_top_right {
                flex: 1;
                color: #666666;
                padding-top: 8px;
            }
        }

        .left_bottom {
            max-width: 580px;
            display: flex;
            flex-direction: row;

            .left_bottom_left {
                width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #666666;

            }

            .left_bottom_center {
                width: 2px;
                background: #F3F3F3;
                margin-left: 5px;
                margin-right: 15px;
            }

            .left_bottom_right {
                flex: 1;
                padding: 12px;
                box-sizing: border-box;
                background-color: #FAFAFA;
                margin-top: 8px;
                margin-bottom: 8px;
                font-size: 14px;
            }
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
        margin-right: 2px;
        position: relative;

        .score_btn {
            margin-top: 150px;
        }

        .video-box {
            width: 371px;
            height: 660px;
            background-color: #0C0E3F;
            border-radius: 8px;
            position: absolute;
            top: 0;
            overflow: hidden;
        }
    }
}

/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
.content-box:first-child .left_top .left-top-border-box {
    width: 0;
}
.content-box:last-child .left_bottom .left_bottom_center {
    width: 0;
}
