
.scrollbar-box {
  background-color: #fff;
  height: 100%;
}
.detail-scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px 20px;
    }
  }
}
.detail-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  margin-top: 20px;
  .detail-header {
    display: flex;
    height: 60px;
    background: #f6f6f6;
    padding: 0 50px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    border-bottom: none;
    .header-item {
      display: flex;
      align-items: center;
      flex: 1;
      width: 1%;
      color: #666666;
      font-size: 16px;
    }
  }
  .detail-body {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .body-item {
      height: 70px;
      display: flex;
      padding: 0 50px;
      box-sizing: border-box;
      //align-items: center;
      border: 1px solid #e5e5e5;
      border-bottom: none;
      .item-left,
      .item-right {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        flex: 1;
        width: 1%;
        .el-input {
          margin-left: 8px;
          width: 290px;
        }
        &.item-left {
          span {
            min-width: 70px;
            text-align: right;
          }
        }
      }
    }
  }
}
