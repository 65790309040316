
.data-header {
  width: 100%;

  margin-left: 30px;
  margin-top: 30px;
}
.service-data-scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px 20px;
    }
  }
}
.service-data-header {
  padding: 0px 0 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f1f5ff;
  .header-left {
    display: flex;
    align-items: center;
    span {
      line-height: 1;
    }
  }
}
.service-data-table {
  margin-top: 20px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-table-header {
    display: flex;
    background: #f6f6f6;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    span {
      color: #666666;
      font-size: 16px;
    }
    .table-header-item {
      flex: 1;
      width: 1%;
      box-sizing: border-box;
    }
  }
  .data-table-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .table-body-item {
      display: flex;
      flex-direction: column;
      .body-item-top,
      .body-item-bottom {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #eeeeee;
        .item-top-item,
        .item-bottom-item {
          width: 1%;
          flex: 1;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            //width: 100%;
            display: inline-block;
          }
          i {
            line-height: 1;
            cursor: pointer;
            font-size: 18px;
            color: #7d7d7d;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
