
.standard {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
    .standard_title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        background-color: #f2f2f2;
        height: 50px;
        border-radius: 10px 10px 0 0;
        font-size: 15px;
        /*padding: 10px;*/
        /*box-sizing: border-box;*/
    }
    .standard_content {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        background-color: #fcfdff;
        border-bottom: 1px solid #dedede;
        /*padding: 10px;*/
        /*box-sizing: border-box;*/
    }
    .one {
        display: flex;
        flex: 0.1;
        justify-content: center;
    }
    .two {
        flex: 0.4;
        /*padding-right: 180px;*/
        padding: 10px 180px 10px 0;
    }
    .three {
         flex: 0.4;
        /*padding-right: 180px;*/
        padding: 10px 180px 10px 0;
     }
    .four {
        display: flex;
        flex: 0.1;
        justify-content: center;
    }
}
.newLine {
    word-break: break-all;
    word-wrap: break-word;
}

.dataAnalysisService {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    .dataAnalysisService-header {
        width: 100%;
        padding-bottom: 10px;
        margin-left: 30px;
        margin-top: 30px;
    }

    .dataAnalysisService_content {
        height: calc(100% - 54px);
        /*padding: 0 30px 30px 30px;*/
        box-sizing: border-box;
    }

    .dataAnalysisService_content_box {
        display: flex;
        flex-direction: column;
        .title {
            margin-top: 30px;
            display: inline-flex;
            flex-direction: row;
            line-height: 16px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #13131B;
            padding-left: 30px;
        }

        .two_title {
            padding-left: 30px;
            width: 100px;
            font-size: 17px;
            font-family: PingFang SC-Bold, PingFang SC;
            color: #13131B;
        }

        .subject {
            display: inline-flex;
            flex-direction: row;
            margin-top: 30px;
            padding-left: 30px;
            align-items: flex-start;
        }

        .Analysis {
            background-color: #F2F4F7;
            margin-top: 30px;
            padding: 20px;
            display: inline-flex;
            flex-direction: row;
        }
    }

}

/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}

/deep/ .p_mg > p {
    margin: 0;
}
